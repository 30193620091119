type VerticalPosition = "top" | "bottom";
type HorizontalPosition = "left" | "right";
type Position = `${VerticalPosition}-${HorizontalPosition}`;

interface DotProps {
  className?: string;
}

const getClassName = (position?: Position) => {
  if (!position) return "";
  switch (position) {
    case "top-left":
      return "top-0 left-0 -translate-x-[3.5px] -translate-y-[3.5px]";
    case "bottom-left":
      return "bottom-0 left-0 -translate-x-[3.5px] translate-y-[3.5px] z-10";
    case "top-right":
      return "top-0 right-0 translate-x-[3.5px] -translate-y-[3.5px]";
    case "bottom-right":
      return "bottom-0 right-0 translate-x-[4px] translate-y-[4px]";
  }
};

const Dot = ({ className }: DotProps) => {
  return (
    <span
      className={`border border-white w-[8px] h-[8px] absolute bg-[#81838D] inline-block rounded-full ${className}`}
    ></span>
  );
};

export default Dot;
