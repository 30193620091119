import { ChangeEvent, useState, MouseEvent, useRef } from "react";

import Image from "next/image";

import CloseIcon from "../../assets-protocol-v4/images/close.svg";
import Input from "../Input";
import SecondaryButton from "../SecondaryButton";

interface Props {
  close: () => void;
}

const FizzNotifyModal = ({ close }: Props) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(null);
    setEmail(e.target.value);
    setIsValid(validateEmail(e.target.value));
  };

  const handleNotifyClick = async (e: MouseEvent) => {
    e.preventDefault();
    const trimmedEmail = email.trim();
    if (!trimmedEmail)
      return setMessage({ type: "error", message: "Enter your email" });
    if (!/^[^@]+@[^@]+\.[^@]+$/.test(trimmedEmail)) {
      setMessage({ type: "error", message: "Invalid email" });
      return;
    }
    setIsLoading(true);

    const response = await fetch("/api/fizz-notify", {
      method: "POST",
      body: JSON.stringify({ email: trimmedEmail }),
    });
    const json = await response.json();
    if (json.status === "success") {
      document.cookie = `fizzNotifyEmail=${email}; Max-Age=31536000; Domain=.spheron.network`;
      setMessage({
        type: "success",
        message: "You will be notified when fizz node is live",
      });
      timerRef.current = setTimeout(() => close(), 1500);
    }
    if (json.status === "error")
      setMessage({
        type: "error",
        message: json.message,
      });

    setIsLoading(false);
  };

  return (
    <div className="isolate z-[100] fixed inset-0 bg-black bg-opacity-40">
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white px-8 py-12 lg:p-14 text-center lg:w-auto lg:h-auto w-[90vw]">
        <span
          className="absolute right-2 top-2 cursor-pointer"
          onClick={() => {
            if (timerRef.current) clearTimeout(timerRef.current);
            close();
          }}
        >
          <Image alt="" src={CloseIcon} />
        </span>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h2 className="font-sans mx-auto lg:text-[32px] lg:leading-[44px] text-[28px] leading-[38px] lg:w-max">
              GET NOTIFIED ABOUT FIZZ NODE LAUNCH!
            </h2>
            <p className="font-inter leading-6 text-sm md:text-base px-[2%] lg:px-[5%]">
              Be the first to know when Fizz Node goes live. Drop your email
              below, and we&apos;ll keep you in the loop!
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <form className="flex gap-2 md:gap-4 mx-auto md:flex-row md:justify-center w-full flex-col items-start">
              <div className="flex flex-col gap-2 w-full md:w-[374px]">
                <Input
                  autoFocus
                  className=""
                  placeholder="ENTER YOUR EMAIL ADDRESS"
                  value={email}
                  onChange={handleEmailChange}
                />
                <span
                  className={
                    "text-sm text-left " +
                    (message?.type === "success"
                      ? "text-green-900"
                      : "text-red-900")
                  }
                >
                  {message?.message}
                </span>
              </div>

              <div className="h-12 w-full md:w-[151px]">
                <SecondaryButton
                  type="submit"
                  disabled={isLoading || !isValid}
                  variant="dark"
                  onClick={handleNotifyClick}
                  loading={isLoading}
                >
                  NOTIFY ME
                </SecondaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FizzNotifyModal;
