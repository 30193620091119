import { ComponentProps } from "react";

const Input = ({ className, ...props }: ComponentProps<"input">) => {
  return (
    <input
      className={
        "rounded-none h-12 outline-none px-3 border border-[#44444B] text-sans " +
        className
      }
      {...props}
    />
  );
};

export default Input;
